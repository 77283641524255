import { EspErrorResponse } from '../_models/esp-error.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EspPaymentService } from '../_services/esp-payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaymentMethodType } from 'src/app/shared/models/payment/payment-dto.model';
import { ESP_PAYMENT_CONFIRMATION, ESP_ROUTE } from '../_consts/esp-payment-route.const';
import { EspPaymentDto, EspPaymentStatus } from '../_models/esp-payment.model';
import { ChoosenPaymentType } from 'src/app/shared/components/choose-payment-method/choosen-payment-type.model';
import { switchMap } from 'rxjs/operators';
import { ExternalPaymentsContentModel } from '../_models/esp-payment-content.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
  espPayment: EspPaymentDto;
  content: ExternalPaymentsContentModel;
  PENDING_STATUSES = [EspPaymentStatus.AUTHPENDING];
  timeInterval: Subscription;
  splashEnabled = false;

  constructor(
    private route: ActivatedRoute,
    private espService: EspPaymentService,
    private translate: TranslateService,
    protected router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.getPaymentInfoAndTranslationTexts();
  }

  getPaymentInfoAndTranslationTexts() {
    this.espService.getEspPayment(this.route.snapshot.paramMap.get('id')).subscribe({
      next: (payment: EspPaymentDto) => (this.espPayment = payment),
      error: (error: EspErrorResponse) => this.handlePaymentError(null, error)
    });

    this.translate.get('EXTERNAL_PAYMENTS').subscribe({
      next: (content: ExternalPaymentsContentModel) => (this.content = content)
    });
  }

  handlePaymentError(toastrMessage: string, error?: EspErrorResponse) {
    this.splashEnabled = false;
    toastrMessage ? this.toastrService.error(toastrMessage) : null;

    for (const e of error.error.errors) {
      e.userMessage ? this.toastrService.error(e.userMessage) : null;
      if (e.code === 'payment-already-started-or-accepted') {
        this.router.navigate(['/', ESP_ROUTE, ESP_PAYMENT_CONFIRMATION, e.path]);
      }
    }
  }

  onSubmit(event: ChoosenPaymentType) {
    switch (event.paymentType) {
      case PaymentMethodType.BLIK:
        this.proceedWithBlik(event.blikCode);
        break;
      case PaymentMethodType.E_CARD:
        this.proceedWithECard();
        break;
      case PaymentMethodType.PAYMENT_CARD:
        this.proceedWithPaymentCard();
        break;
      default:
        console.error('Nieobsługiwany typ płatności');
        break;
    }
  }

  private proceedWithBlik(blikCode: string) {
    this.splashEnabled = true;

    this.espService
      .createBlikPayment$(this.espPayment.id, this.createBlikObject(blikCode))
      .subscribe({
        next: (result) => {
          if (this.PENDING_STATUSES.includes(result.paymentStatus)) {
            this.poolBlikPaymentStatus(result.billId);
          } else if (result.paymentStatus === EspPaymentStatus.EXCEEDED) {
            this.handlePaymentError(this.content.CONFIRMATION.EXCEEDED.paymentInfo);
          } else {
            this.handleBlikPaymentStatus(result);
          }
        },
        error: (error) => {
          this.handlePaymentError(null, error);
        }
      });
  }

  private createBlikObject(code: string) {
    return {
      blikCode: code
    };
  }

  private poolBlikPaymentStatus(billId: string): void {
    this.timeInterval = interval(3000)
      .pipe(switchMap(() => this.espService.getPaymentStatus(billId)))
      .subscribe({
        next: (bill) => this.handleBlikPaymentStatus(bill),
        error: (error) => this.handlePaymentError(null, error)
      });
  }

  private handleBlikPaymentStatus(bill): boolean {
    if (bill.paymentStatus) {
      this.splashEnabled = false;
      this.timeInterval ? this.timeInterval.unsubscribe() : null;
      this.router.navigate(['/', ESP_ROUTE, ESP_PAYMENT_CONFIRMATION, bill.billId]);
    }
    return false;
  }

  private proceedWithECard() {
    this.espService.createPayment$(this.espPayment.id).subscribe({
      next: (result) => {
        if (result.paymentStatus === EspPaymentStatus.EXCEEDED) {
          this.handlePaymentError(this.content.CONFIRMATION.EXCEEDED.paymentInfo);
        } else {
          window.location.href = result.redirectUrl;
        }
      },
      error: (error) => {
        this.handlePaymentError(null, error);
      }
    });
  }

  proceedWithPaymentCard() {
    this.proceedWithECard();
  }
  ngOnDestroy(): void {
    this.timeInterval ? this.timeInterval.unsubscribe() : null;
  }
}
