import { EspPaymentItem } from './esp-payment-item.model';

export interface EspPaymentDto {
  espPaymentId: string;
  fee: number;
  id: string;
  items: EspPaymentItem[];
  registerFeeSum: number;
  systemId: string;
  taxFeeSum: number;
  provision: number;
}

export interface PayWithRedirectResponse {
  billId: string;
  paidAmount: number;
  paymentId: string;
  paymentStatus: EspPaymentStatus;
  redirectUrl: string;
}

export interface PayWithBlikResponse {
  billId: string;
  fee: number;
  paidAmount: number;
  paymentId: string;
  paymentStatus: EspPaymentStatus;
  registerFeeSum: number;
  taxFeeSum: number;
  urlFailure: string;
  urlSuccess: string;
}

export interface BillDto {
  billId: string;
  fee: number;
  id: string;
  paymentStatus: EspPaymentStatus;
  provision: number;
  redirectUrl: string;
  registerFeeSum: number;
  taxFeeSum: number;
}

export enum EspPaymentStatus {
  AUTHPENDING = 'AUTHPENDING',
  AUTHACCEPTED = 'AUTHACCEPTED',
  AUTHREJECTED = 'AUTHREJECTED',
  EXCEEDED = 'EXCEEDED'
}
