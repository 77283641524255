import { FormOfRefundDto } from 'src/app/shared/models/applications/form-of-refund-dto.model';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import {
  BaseFormBinderService,
  FormValidation
} from 'src/app/shared/services/form/base-form-binder.service';

@Injectable()
export class RefundFormDataFormBinder extends BaseFormBinderService {
  protected createForm(fieldValidations?: FormValidation, formGroupNames?: any): UntypedFormGroup {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(null, validator));
    });
    fg.controls['refundType'].valueChanges.subscribe((value) => {
      this.refundTypeValueChange(value);
    });
    fg.controls['refundType'].setValue('BANK_TRANSFER');
    return fg;
  }

  public isRefundTypeNull(): boolean {
    return !this.getControlValue('refundType');
  }

  public setRefundTypeTo(value: FormOfRefundDto.RefundTypeEnum): void {
    this.set('refundType', value);
  }

  private refundTypeValueChange(value: any) {
    if (!this.form) return;
    if (value === 'BANK_TRANSFER') {
      this.enable('bankAccountNumber');
      if (this.get('bankAccountNumber') === ' ') this.set('bankAccountNumber', '');
    } else {
      this.disable('bankAccountNumber');
      this.form.controls['bankAccountNumber'].reset();
      this.set('bankAccountNumber', ' ');
    }
  }
}
