import { PersonDataFormValidation } from './person-data.model';
import { Validators } from '@angular/forms';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';
import { ValidationFunctions } from 'src/app/shared/validation/validation-functions.const';

/**
 * @Summary To disable field set its value to null.
 * Empty validation has to have Validator.nullValidator since Validators.compose([]) == false.
 * DO NOT ADD REQUIRED VALIDATOR TO PESEL. It is added and removed based on noPeselCheckbox state.
 */
export const defaultPersonDataValidation: PersonDataFormValidation = {
  formGroupName: 'personData',

  formFields: {
    combinedName: null,
    hasNoPesel: Validators.compose([Validators.nullValidator]),
    firstName: Validators.compose([
      Validators.required,
      ValidationFunctions.maxLengthValidator(40),
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),
    ]),
    middleName: Validators.compose([
      ValidationFunctions.maxLengthValidator(40),
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    lastName: Validators.compose([
      Validators.required,
      ValidationFunctions.maxLengthValidator(40),
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    birthDate: Validators.compose([
      Validators.minLength(8),
      Validators.maxLength(11),
      Validators.pattern(ValidationPatterns.date),
      ValidationFunctions.noFutureDateValidator()
      // TODO ogarnac jak zrobic fireValidation
      // ValidationFunctions.fireValidation('releaseDate')
    ]),
    birthPlace: Validators.compose([
      Validators.maxLength(48),
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    nip: null,
    pesel: Validators.compose([
      // Validators.required,
      Validators.pattern(ValidationPatterns.pesel),
      Validators.maxLength(11),
      Validators.minLength(11),
      ValidationFunctions.peselControlSum()
    ]),
    personalDocumentType: Validators.compose([Validators.nullValidator, Validators.required]),
    personalDocumentNumber: Validators.compose([
      Validators.maxLength(14),
      Validators.required,
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    personalDocumentIssuingAuthority: Validators.compose([
      Validators.maxLength(150),
      Validators.required,
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    email: Validators.compose([
      Validators.pattern(ValidationPatterns.email),
      ValidationFunctions.maxLengthValidator(80)
    ]),
    phoneNumber: Validators.compose([
      Validators.pattern(ValidationPatterns.phoneNumber),
      ValidationFunctions.sanitizedPhoneNumberLength(7, 16)
    ]),
    certificateNumber: Validators.compose([
      Validators.maxLength(25),
      Validators.required,
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),
    ])
  }
};
