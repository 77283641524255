<section class="payment__method" *ngIf="content">
  <h2 class="payment__method--title">
    {{ titleText ? titleText : content.howToPay }}
  </h2>
  <div class="payment__method-wrapper">
    <label
      class="payment__method--card"
      *ngIf="!isPeriodic"
      [class.payment__method--card-selected]="selectedPaymentMethod === PaymentMethodType.BLIK"
      [attr.aria-expanded]="selectedPaymentMethod === PaymentMethodType.BLIK">
      <img src="assets/images/blik-active.png" alt="payment method icon" />
      <div class="payment__method--card-input-box">
        <input
          class="tile__input"
          type="radio"
          name="payment-method-type"
          [(ngModel)]="selectedPaymentMethod"
          [value]="PaymentMethodType.BLIK" />
        <div class="payment__method--card-input-box-checkbox"></div>
        <span>{{ content.blik }}</span>
      </div>
    </label>

    <div
      class="payment__method--blik-code ic-input"
      *ngIf="!isPeriodic"
      [class.payment__method--blik-code-show]="selectedPaymentMethod === PaymentMethodType.BLIK"
      [attr.aria-hidden]="selectedPaymentMethod !== PaymentMethodType.BLIK">
      <label for="blik">{{ content.blickCode }}</label>
      <div class="input-flex">
        <input
          id="blik-mobile"
          type="text"
          [class.invalid]="checkIfBlikCodeIsInvalid()"
          [formControl]="blikCodeControl"
          [attr.aria-label]="content.blikPlaceholder"
          placeholder="{{ content.blikPlaceholder }}"
          [value]="blikCodeControl.value"
          [attr.title]="content.blikPlaceholder" />
        <div *ngIf="checkIfBlikCodeIsInvalid()" class="error-icon"></div>
      </div>
      <div class="error-description-container" *ngIf="checkIfBlikCodeIsInvalid()">
        <span class="error-description">
          {{ content.blikError }}
        </span>
      </div>
    </div>

    <label
      *ngIf="!isPeriodic"
      class="payment__method--card"
      [class.payment__method--card-selected]="selectedPaymentMethod === PaymentMethodType.E_CARD">
      <div class="tooltip-icon">i</div>
      <div class="tooltip-wrapper">
        <img src="assets/images/payment-options.png" alt="Company tooltip" />
      </div>
      <img class="tile__icon" src="assets/images/e-card.PNG" alt="payment method icon" />
      <div class="payment__method--card-input-box">
        <input
          class="tile__input"
          type="radio"
          name="payment-method-type"
          [(ngModel)]="selectedPaymentMethod"
          [value]="PaymentMethodType.E_CARD" />
        <div class="payment__method--card-input-box-checkbox"></div>
        <span>{{ content.transfer }}</span>
      </div>
    </label>

    <label
      class="payment__method--card"
      [class.payment__method--card-selected]="
        selectedPaymentMethod === PaymentMethodType.PAYMENT_CARD
      ">
      <div class="tooltip-icon">i</div>
      <div class="tooltip-wrapper reverse">
        <img src="assets/images/payment-options.png" alt="Company tooltip" />
      </div>
      <img class="tile__icon" src="assets/images/payment-card.PNG" alt="payment method icon" />
      <div class="payment__method--card-input-box">
        <input
          class="tile__input"
          type="radio"
          name="payment-method-type"
          [(ngModel)]="selectedPaymentMethod"
          [value]="PaymentMethodType.PAYMENT_CARD" />
        <div class="payment__method--card-input-box-checkbox"></div>
        <span>{{ content.card }}</span>
      </div>
    </label>
  </div>
</section>

<section class="payment__buttons" *ngIf="content">
  <ic-ghost-button
    *ngIf="showBackButton"
    [typeHTML]="'button'"
    (click)="backClick()"
    [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
    [showArrow]="true"
    [arrowLeft]="true"
    [width]="140">
    <span class="forwardSpan start-btn__label" [innerHtml]="content.backBtn"></span>
  </ic-ghost-button>
  <ic-ghost-button
    [typeHTML]="'button'"
    appPreventDoubleClick
    (throttledClick)="onSubmit()"
    [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
    [showArrow]="true"
    [width]="140">
    <span
      class="forwardSpan start-btn__label"
      innerHtml="{{ payButtonText ? payButtonText : content.payBtn }}"></span>
  </ic-ghost-button>
</section>
