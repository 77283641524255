const WP_API_HOST = window['env']['WP_API_HOST'];
const BUILD_VERSION = window['env']['BUILD_VERSION'];
const ENVIRONMENT_URL = window['env']['ENVIRONMENT_URL'];

export const environment = {
  BUILD_VERSION: BUILD_VERSION,
  production: window['env']['PRODUCTION'],
  WP_API_INFOCAR_URL: `${WP_API_HOST}/infocar`,
  WP_API_HOST: WP_API_HOST,
  WP_API_INFOCAR_NAMESPACE: '/infocar',
  WP_API_DEFAULT_NAMESPACE: '/wp/v2',
  JSON_SERVER_URL: 'http://localhost:3000',
  SLIDER_ENDPOINT: "/slider",
  REDIRECT_URI: window['env']['REDIRECT_URI'],
  SILENT_REFRESH_REDIRECT_URI: window['env']['SILENT_REFRESH_REDIRECT_URI'],
  AUTH_ISSUER: `${ENVIRONMENT_URL}/oauth2/`,
  AUTH_LOGOUT_URL: `${ENVIRONMENT_URL}/oauth2/endsession`,
  MAP_TEMPLATE_STYLES: 'https://cdn.info-car.pl/maps/styles/',

  ASSETS: {
    AUTHORIZATION: 'upowaznienia/',
    APPLICATIONS: 'wnioski/',
    BASE: 'assets/',
    DOCUMENTS: 'dokumenty/',
    FONTS: 'fonts/',
    I18N: 'i18n/',
    ICONS: 'icons/',
    IMAGES: 'images/',
    POLITICS: 'polityki/',
    STATEMENTS: 'oswiadczenia/'
  },

  CMS_API: {
    INFOCAR_URL: `${WP_API_HOST}/infocar`,
    DEFAULT_URL: `${WP_API_HOST}/wp/v2`
  },

  INFOBARS_ENDPOINT: `${ENVIRONMENT_URL}/api/infobars/`,

  BACKEND_API: {
    ANONYMOUS: '/anonymous',
    OBJECTS: '/objects',
    KIND: '/kind',
    BASE: `${ENVIRONMENT_URL}/api/stc`,
    BASE_ACCOUNT_MANAGMENT: `${ENVIRONMENT_URL}/api/account-management`,
    STC_STATUS: '/check-status',
    EMPLOYERS: '/employers',
    EMPLOYEES: '/employees',
    EMPLOYEES_BY_REASON: '/employees/reason-type',
    EMPLOYEES_SEARCH: '/employees/search',
    EDOHUB: '/edohub',
    REASONS: '/reasons',
    CARDHOLDERS: '/cardholders',
    APPLICATIONS: '/applications',
    PAPER_FORM: '/paper-forms',
    CORRECTIONS: '/corrections',
    ATTACHMENTS: '/attachments',
    REQUIRED_ATTACHMENTS: '/required-attachments',
    CONFIRM_SUMMARY: '/confirm-summary',
    SIGN: '/sign',
    SIGN_QUALIFIED: '/sign-qualified',
    SIGN_STATUS: '/front/sign-status',
    SIGN_LINK: '/link',
    SIGN_CANCEL: '/front/cancel',
    JNLP_URL: '/jnlp-url',
    IS_EPUAP_AVAILABLE: '/is-epuap-available',
    IS_EDO_AVAILABLE: '/is-edo-available',
    CARD_PROPERTIES: '/card-properties',
    DRIVER_CARD: '/driver-card',
    TEMPORARY_DRIVER_CARD: '/temporary-driver-card',
    WORKSHOP_CARD: '/workshop-card',
    CONTROL_CARD: '/control-card',
    COMPANY_CARD: '/company-card',
    SAVE_CARD_SHIPPING_ADDRESS: '/save-card-shipping-address',
    SAVE_SHIPPING_ADDRESSES: '/save-shipping-addresses',
    IS_PAPER: '/is-paper',
    PAYMENTS: '/payments',
    PERSONS: '/persons',
    STATUS: '/status',
    STATUSES: '/statuses',
    BACK: '/back/',
    PERSONAL: '/personal',
    PROCEED_ATTACHMENTS: '/proceed-attachments',
    ACCOUNT_MANAGMENT: '/account-management',
    CLIENT: '/client',
    COMPLAINT: '/complaint',
    COMPLAINT_REASONS: '/complaints/reasons',
    SUMMARY_AGGREGATION: '/summary',
    PAYMENT_MANUAL: '/payment-manual',
    MANUAL_PAYMENT_SUMMARY: '/manual-payment-summary'
  },

  CORRECTIONS: {
    ERROR_ENTRIES: '/error-entries',
    ERROR_ENTRIES_FILTERS: {
      PARAM_NAME: 'filter=',
      PERSONAL_DATA: 'onlyApplicantEntries',
      ATTACHMENTS_ONLY: 'onlyAttachments'
    }
  },

  ACCOUNT_MANAGEMENT: {
    BASE: `${ENVIRONMENT_URL}/api/account-management`,
    ACCOUNT_MANAGEMENT: '/account-management',
    BUSINESS_CLIENT: '/business-client',
    INDIVIDUAL_CLIENT: '/individual-client',
    CLIENT: '/client',
    AVAILABLE_ACCEPTANCES: '/available-acceptances',
    STC: '/stc',
    RESET_PASSWORD: '/reset-password',
    CHANGE_PASSWORD: '/change-password'
  },

  WORD: {
    BASE: `${ENVIRONMENT_URL}/api/word`,
    HOLIDAYS: '/holidays',
    EXAM_SCHEDULE: `${ENVIRONMENT_URL}/api/word-exam-schedule`,
    OSKS: {
      BASE: '/osks',
      CITYID_FILTER: '?cityId='
    },
    RESERVATIONS: {
      BASE: '/reservations',
      PAYMENTS: '/payments',
      CANCEL: '/cancel'
    },
    PDFS: '/pdfs',
    PAYMENTS: {
      BASE: '/payments',
      ACCOUNT: '/account',
      BLIK: '/blik',
      REDIRECT: '/redirect',
      BILLS: '/bills'
    },
    PROVINCES: {
      BASE: '/provinces',
      CITIES: '/cities'
    },
    DISTRICT_OFFICES: {
      BASE: '/district-offices'
    },
    CENTERS: {
      BASE: '/word-centers',
      SCHEDULE: '/exam-schedule',
      WORD: '/word',
      WORDS: '/words',
      OFFLINE: '/offline'
    }
  },

  DEALS: {
    BASE: `${ENVIRONMENT_URL}/api/deals`,
    STC_PACK: '/stc-pack'
  },

  STC: {
    TOKEN: `${ENVIRONMENT_URL}/oauth/token`,
    ME: `${ENVIRONMENT_URL}/me`
  },
  NEW: '/new',

  ESP: {
    BASE: `${ENVIRONMENT_URL}/api/esp-payment`,
    PAYMENTS: '/payments',
    BILLS: '/bills',
    REDIRECT: '/redirect',
    BLIK: '/blik'
  },

  POSK: {
    BASE: `${ENVIRONMENT_URL}/api/osk`,
    LICENCE: '/license-payments',
    WORD: '/word-payments',
    PAYMENTS: '/payments',
    BILLS: '/bills',
    REDIRECT: 'redirect',
    BLIK: 'blik',
    REDIRECT_URL: 'https://www.portalosk.pl/'
  },

  SSI: {
    BASE: `${ENVIRONMENT_URL}/api/ssi`,
    STATUS: '/status',
    REGISTRATION_DOCUMENT: '/driver/registration-document',
    PKK: '/pkk/pkk-status',
    DRIVER_LICENCE: '/driver/driver-licence'
  },

  ADR: {
    BASE: `${ENVIRONMENT_URL}/api/adr`,
    STATUS: '/status'
  },

  SUBSCRIPTIONS: {
    BASE: `${ENVIRONMENT_URL}/api/subscriptions`,
    WEBPUSH: '/webpush',
    KEY: '/key',
    TOPICS: '/topics'
  },

  PIK_PAYMENT: {
    BASE: `${ENVIRONMENT_URL}/api/pik-payment`,
    MAP: '/map',
    CATALOG: 'catalog',
    CATEGORY: 'category',
    DOCUMENT_PAYMENT: {
      BLIK: '/blik',
      PURCHASE: '/purchase-orders',
      REDIRECT: '/redirect',
      PAYED: '/payed',
      CONFIRMATION: '/confirmation'
    }
  },
  ATTESTATION: {
    BASE: `${ENVIRONMENT_URL}/api/stc-attestation`,
    REPORT: '/reports'
  },

  FEATURE_TOGGLES: window['env']['FEATURE_TOGGLES'],
  auth: {
    OAUTH_ALLOWED_URLS: window['env']['OAUTH_ALLOWED_URLS']
  }
};

