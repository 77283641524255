import { MODAL_OVERLAY_CLICKED } from 'src/app/shared/components/modal/modal-ref';
import { AppVersionChangedModalComponent } from './shared/components/modal/app-version-changed-modal/app-version-changed-modal.component';
import { AppVersionService } from './shared/services/app-version/app-version.service';
import { TranslateService } from '@ngx-translate/core';
import { trigger } from '@angular/animations';
import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FADEOUT_ANIMATION } from './shared/extensions/animations';
import { PushNotificationsService } from './shared/services/push-notifications/push-notifications.service';
import { version } from 'os';
import { ModalRef } from './shared/components/modal/modal-ref';
import { ModalService } from './shared/components/modal/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [trigger('fadeOut', FADEOUT_ANIMATION)]
})
export class AppComponent implements OnDestroy {
  public title = 'Info-Car';
  routerLoaded = false;
  routerSubscription: Subscription;
  routerEventsSubscription: Subscription;
  focusOnTab = false;
  appVersion: string;
  modalRef: ModalRef;

  constructor(
    private router: Router, // private pushService: PushNotificationsService,
    private translate: TranslateService,
    private appVersionService: AppVersionService,
    private modal: ModalService
  ) {
    this.waitForRouterToResolveData();
    if (localStorage.getItem('lang')) {
      this.translate.use(localStorage.getItem('lang'));
    } else {
      localStorage.setItem('lang', 'pl');
    }
    // this.translate.use('pl');

    this.appVersionService.checkAppVersion(30000).subscribe({
      next: value => {
        if (!this.appVersion) {
          this.appVersion = value;
        }

        if (this.appVersion !== value && !this.modalRef) {
          this.modalRef = this.modal.open(AppVersionChangedModalComponent);
        }

        if (this.modalRef) {
          const sub = this.modalRef.afterClosed.subscribe((value) => {
            if (!value || value === MODAL_OVERLAY_CLICKED) {
              const modalTimeout = setTimeout(() => {
                this.modalRef = null
                sub.unsubscribe();
                clearTimeout(modalTimeout);
              }, 15 * 60 * 1000)
            } else {
              sub.unsubscribe();
            }
          });
        }


      },
      error: err => {
        // Silent error
        this.appVersion === '1.0.0'
      }
    })
  }

  private waitForRouterToResolveData() {
    this.routerSubscription = this.router.events.subscribe({
      next: (x) => {
        if (x instanceof NavigationEnd || x instanceof NavigationError) {
          this.routerLoaded = true;

          // if (environment.FEATURE_TOGGLES.PUSH_NOTIFICATIONS) {
          //   this.pushService.registerServiceWorker();
          // }
        }
      },
      complete: () => {
        this.routerSubscription.unsubscribe();
      }
    });
  }

  addFocusClass() {
    this.focusOnTab = true;
  }

  removeFocusClass() {
    this.focusOnTab = false;
  }

  changeLng(lang: string) {
    this.translate.use(lang).subscribe;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
  ngOnDestroy(): void {
    this.routerEventsSubscription.unsubscribe();
  }
}
