<section class="payment__summary" *ngIf="payment && !isMobile">
  <h2 *ngIf="!titleAsH1" class="payment__summary--title">
    {{ content.chooseMethod }}
  </h2>
  <h1 *ngIf="titleAsH1" class="payment__summary--title">
    {{ content.chooseMethod }}
  </h1>

  <div class="payment__summary--row-wrapper">
    <div class="payment__summary--row">
      <div class="payment__summary--left-column">
        <p>{{ content.type }}</p>
      </div>
      <!-- NOTE -->
      <!-- "administrativeFee": "Opłata administracyjna", -->
      <div class="payment__summary--administrative-fee">
        {{ content.administrativeFee }}
      </div>
      <!-- NOTE -->
      <!-- "taxFee": "Opłata ewidencyjna", -->
      <div class="payment__summary--register-fee">
        {{ content.taxFee }}
      </div>
      <div class="payment__summary--right-column payment__summary--grid-last">
        <p>{{ content.price }}</p>
      </div>
    </div>
    <ng-container *ngFor="let item of payment.items">
      <div class="payment__summary--row">
        <div class="payment__summary--left-column">
          <p>
            <strong>{{ item.documentType }}</strong>
          </p>
        </div>
        <!-- NOTE -->
        <div class="payment__summary--administrative-fee">
          <p>
            <strong>{{ item.administrativeFee / 100 }} {{ content.units }}</strong>
          </p>
        </div>
        <div class="payment__summary--register-fee">
          <p>
            <strong>{{ item.registerFee / 100 }} {{ content.units }}</strong>
          </p>
        </div>
        <div class="payment__summary--right-column payment__summary--grid-last">
          <p>
            <strong
              >{{ (item.registerFee + item.administrativeFee) / 100 }} {{ content.units }}</strong
            >
          </p>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- NOTE -->
  <div class="payment__summary--row" *ngIf="payment.provision > 0">
    <div class="payment__summary--left-column">
      <p>{{ content.provision }}</p>
    </div>
    <div class="payment__summary--right-column payment__summary--grid-last">
      <p>
        <strong>{{ payment.provision / 100 | number: '1.2-2' }} {{ content.units }}</strong>
      </p>
    </div>
  </div>

  <div class="payment__summary--row payment__summary--sum">
    <div class="payment__summary--left-column">
      <p>
        <strong>{{ content.priceSum }}</strong>
      </p>
    </div>
    <div class="payment__summary--right-column payment__summary--grid-last">
      <p>
        <strong
          >{{ (payment.fee + payment.provision) / 100 }}
          <!-- NOTE -->
          <!--  -->
          {{ content.units }}</strong
        >
      </p>
    </div>
  </div>
</section>

<section class="payment__summary--mobile" *ngIf="payment && isMobile">
  <h2 *ngIf="!titleAsH1" class="payment__summary--title">
    {{ content.chooseMethod }}
  </h2>
  <h1 *ngIf="titleAsH1" class="payment__summary--title">
    {{ content.chooseMethod }}
  </h1>

  <div class="payment__summary--mobile-row-wrapper">
    <div class="payment__summary--mobile-row m-0">
      <div class="payment__summary--mobile-left-column">
        <p>{{ content.type }}</p>
      </div>
      <div class="payment__summary--mobile-right-column">
        <p>{{ content.price }}</p>
      </div>
    </div>
    <ng-container *ngFor="let item of payment.items">
      <div class="payment__summary--mobile-row">
        <div class="payment__summary--mobile-left-column">
          <div class="payment__summary--mobile-type">
            <p>
              <strong>{{ item.documentType }}</strong>
            </p>
          </div>
          <!-- NOTE -->
          <div class="payment__summary--mobile-administrative-fee">
            <p>{{ content.administrativeFee }}</p>
          </div>
          <div class="payment__summary--mobile-register-fee">
            <p>{{ content.taxFee }}</p>
          </div>
        </div>
        <div class="payment__summary--mobile-right-column">
          <div class="payment__summary--mobile-price">
            <p>
              <strong
                >{{ item.registerFee + item.administrativeFee / 100 }}
                <!--  -->
                {{ content.units }}</strong
              >
            </p>
          </div>
          <div class="payment__summary--mobile-administrative-fee">
            <p>
              <strong>{{ item.administrativeFee / 100 }} {{ content.units }}</strong>
            </p>
          </div>
          <div class="payment__summary--mobile-register-fee">
            <p>
              <strong>{{ item.registerFee / 100 }} {{ content.units }}</strong>
            </p>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="payment__summary--mobile-row m-0">
      <!-- NOTE -->
      <div class="payment__summary--mobile-left-column" *ngIf="payment.provision > 0">
        <p>{{ content.provision }}</p>
      </div>
      <div class="payment__summary--mobile-right-column">
        <p>
          <strong>{{ payment.provision / 100 }} {{ content.units }}</strong>
        </p>
      </div>
    </div>
    <div class="payment__summary--mobile-row payment__summary--mobile-sum m-0">
      <div class="payment__summary--mobile-left-column">
        <p>
          <strong>{{ content.priceSum }}</strong>
        </p>
      </div>
      <div class="payment__summary--mobile-right-column">
        <p>
          <strong
            >{{ (payment.fee + payment.provision) / 100 }}
            <!-- NOTE -->
            {{ content.units }}</strong
          >
        </p>
      </div>
    </div>
  </div>
</section>
