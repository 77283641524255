import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseFormBinderService } from 'src/app/shared/services/form/base-form-binder.service';
import { CompanyCardNumberFormValidation } from './company-card-number.model';

@Injectable()
export class CompanyCardNumberFormBinder extends BaseFormBinderService {
  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }
  protected createForm(fieldValidations: CompanyCardNumberFormValidation) {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(null, validator));
    });
    return fg;
  }

  public addRequiredValidatorToCompanyCardNumber(
    validation: CompanyCardNumberFormValidation
  ): CompanyCardNumberFormValidation {
    return {
      formGroupName: validation.formGroupName,
      formFields: {
        companyCardNumber: Validators.compose([
          validation.formFields.companyCardNumber,
          Validators.required
        ])
      }
    };
  }
}
