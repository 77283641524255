import { AddressDataFormValidation } from './address-data.model';
import { Validators } from '@angular/forms';
import { ValidationFunctions } from 'src/app/shared/validation/validation-functions.const';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';

/**
 * @Summary To disable field set its value to null.
 * Empty validation has to have Validator.nullValidator since Validators.compose([]) == false
 */
export const defaultAddressDataValidation: AddressDataFormValidation = {
  formGroupName: 'addressData',

  formFields: {
    countryCode: Validators.compose([Validators.nullValidator]),
    companyName: null,
    street: Validators.compose([
      ValidationFunctions.maxLengthValidator(100),
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    buildingNo: Validators.compose([
      ValidationFunctions.maxLengthValidator(10),
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    apartmentNo: Validators.compose([
      ValidationFunctions.maxLengthValidator(4),
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    city: Validators.compose([
      ValidationFunctions.maxLengthValidator(48),
      Validators.pattern(ValidationPatterns.notCyrillicPattern),
      Validators.pattern(ValidationPatterns.invalidSingleLetters),

    ]),
    postCode: Validators.compose([
      Validators.maxLength(10),
      Validators.pattern(ValidationPatterns.notPolishPostCode)
    ]),
    phoneNumber: Validators.compose([
      Validators.pattern(ValidationPatterns.phoneNumber),
      ValidationFunctions.sanitizedPhoneNumberLength(7, 16)
    ]),
    email: Validators.compose([
      Validators.pattern(ValidationPatterns.email),
      ValidationFunctions.maxLengthValidator(80)
    ]),
    correspondenceAddressCheckbox: Validators.compose([Validators.nullValidator])
  }
};
