import { CompanyDataDto, CompanyDataFormValidation } from './company-data.model';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { BaseFormBinderService } from 'src/app/shared/services/form/base-form-binder.service';
import { ValidationExtension } from 'src/app/shared/validation/validation-extensions.const';

@Injectable()
export class CompanyDataFormBinder extends BaseFormBinderService {
  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }
  protected createForm(fieldValidations: CompanyDataFormValidation) {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(null, validator));
    });
    return fg;
  }

  public fillFormWith(companyData: CompanyDataDto): void {
    if (companyData) {
      Object.entries(companyData).forEach(([name, value]: [string, any]) => {
        this.set(name, value);
      });
    }
  }

  isControlRequired(controlName: string): boolean {
    return (
      controlName &&
      this.form.get(controlName) &&
      ValidationExtension.hasRequiredField(this.form.get(controlName))
    );
  }
}
